import React from 'react';
import '../../../UI/Common/Inputs/CheckBox.css';

interface Props {
    checked: boolean;
    onChange?: any;
}

const CheckBox: React.FC<Props> = ({ checked, onChange }) => {

    return (
        <input
            className='checkbox-input'
            type="checkbox"
            checked={checked}
            onChange={onChange}
        />
    );
}

export default CheckBox;