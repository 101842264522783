import logo_extenso from '../../../Assets/logos/logo_extenso.svg';
import arrow_left from '../../../Assets/Icons/icon-arrow-left-white.svg';
import icon_spacer from '../../../Assets/Icons/icon-spacer-24-px.svg';
import '../../../UI/Pages/Checkin/CheckinBackground.css';

interface Props {
    returnButtonAction?: any;
    children: React.ReactNode;
}

const CheckinBackground: React.FC<Props> = ({ children, returnButtonAction }) => {
    return (
        <div className='checkin-background'>
            <div style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '2.5rem',
                marginBottom: '3.26rem',
                gap: '0.5625rem',
            }}>
                <div style={{ display: 'flex', justifyContent: returnButtonAction ? 'space-between' : 'center', alignItems: 'center', width: '100%' }}>
                    {returnButtonAction &&
                        <button className='app-header-icon-button' style={{ marginTop: '0.5rem' }} onClick={returnButtonAction}>
                            <img src={arrow_left} alt="Go back" />
                        </button>
                    }
                    <img className='logo' src={logo_extenso} />
                    {returnButtonAction && <img src={icon_spacer} />}
                </div>

                <div className='div-beta'>
                    <p className='p-beta'>BETA</p>
                </div>
            </div>


            <div style={{ width: '90%', maxWidth: '425px' }}>
                {children}
            </div>
        </div >
    );
}

export default CheckinBackground;
/*


rightButton = {< img src = { icon_spacer } />
*/