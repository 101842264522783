
import React from 'react';
import '../../../UI/Common/Inputs/FormInput.css';

interface Props {
    type: string;
    id: string;
    value?: any;
    onChange?: any;
    placeholder?: string;
    defaultValue?: string;
    centerText?: boolean;
    maxLength?: number;
    rows?: number;
}

const FormInput: React.FC<Props> = ({ type, id, value, placeholder, defaultValue, onChange, maxLength, centerText, rows }) => {

    if (type === 'textarea') {
        return (
            <textarea
                id={id}
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                maxLength={maxLength}
                rows={rows || 2}  // Define o número de linhas, com valor padrão de 2
                className={`form-input-textarea`}
            />
        );
    }

    return (
        <input
            type={type}
            id={id}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            className={`form-input ${centerText ? 'center-text' : ''} ${value ? "has-value" : ''}`}

        />
    );
}

export default FormInput;
