import close_icon from '../../Assets/Icons/icon-close-white.svg';
import PageTransition from '../Common/PageTransition';

interface Props {
    onClose: () => void;
}

const UseTerms: React.FC<Props> = ({ onClose }) => {

    window.scrollTo(0, 0);
    
    return (
        <>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed', // Fixa a barra no topo
                top: 0,            // Alinha no topo da tela
                right: 0,          // Alinha à direita
                backgroundColor: '#1B1B1C', // Fundo escuro para contrastar
                zIndex: 1000       // Garante que fique acima de outros elementos
            }}>
                <div style={{ padding: '1rem', }}>
                    <img src={close_icon} onClick={onClose} alt="Fechar" />
                </div>
            </div>
            <PageTransition>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                    <div style={{ padding: '1rem', marginTop: '1.5rem' }}>
                        <div>
                            <h2 style={{ color: 'white' }}>Termos de Uso - Blinker</h2>
                        </div>
                        <div>
                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                Data de Vigência: 01/01/2025
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                Bem-vindo ao Blinker! Ao usar nosso aplicativo,
                                você concorda com os Termos de Uso descritos abaixo.
                                Se você tiver dúvidas ou precisar de mais informações, entre em contato conosco através do e-mail contatoblinker@gmail.com.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                1. Introdução
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O Blinker é um aplicativo de relacionamento que permite aos usuários se conectarem em locais físicos por meio de check-ins baseados em geolocalização.
                                Ao fazer o check-in, os usuários podem visualizar e interagir com outras pessoas presentes no mesmo local.
                                O uso do Blinker implica a aceitação destes Termos de Uso. Caso não concorde com estes Termos, pedimos que interrompa o uso do aplicativo.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                2. Elegibilidade e Cadastro
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                Para utilizar o Blinker, você deve ter no mínimo 18 anos de idade.
                                O processo de cadastro exige a inserção de um código de autenticação enviado via SMS para garantir a segurança do usuário.
                                Seus dados pessoais serão protegidos e nunca compartilhados com outros usuários.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O Blinker não inclui um sistema de chat ou interação personalizada, logo,
                                todas as interações no app ocorrem por meio de interações prontas em formato de emojis.
                                Esta medida foi adotada para proteger os usuários contra assédio e mau uso da plataforma.
                                O Blinker não se responsabiliza por interações que ocorram fora do aplicativo.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                Você poderá encerrar sua conta a qualquer momento por meio das configurações do aplicativo.
                                O Blinker também se reserva o direito de encerrar contas que apresentem comportamento inadequado, conforme definido na seção 4.

                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                3. Descrição dos Serviços
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O Blinker permite que os usuários façam check-in em estabelecimentos utilizando pontos de geolocalização públicos.
                                O aplicativo Blinker não possui qualquer vínculo ou relação jurídica com os estabelecimentos listados.
                                A base de dados é extraída diretamente do Google Maps e os nomes exibidos são usados apenas como referência de localização para os usuários.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O envio de interações não garante resposta ou conexão, sendo uma ferramenta de entretenimento e networking,
                                e o Blinker não se responsabiliza por interações que ocorram fora do aplicativo.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                4. Uso Aceitável e Conduta do Usuário
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                Para assegurar um ambiente seguro e respeitoso para todos, pedimos que você siga estas diretrizes ao usar o Blinker:
                            </p>

                            <ul style={{
                                color: 'white',
                                fontFamily: 'Work Sans',
                                fontSize: '1rem',
                                letterSpacing: '0.015rem',
                                paddingLeft: '1rem',
                                marginLeft: '0.5rem',
                            }}>
                                <li>
                                    <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                        Respeito mútuo: Trate outros usuários com respeito. Assédio, importunação, discurso de ódio,
                                        bullying ou qualquer forma de comportamento ofensivo são estritamente proibidos.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                        Autenticidade: Utilize sua foto e nome verdadeiros. Só assim as pessoas conseguiram te identificar e interagir com você.
                                        O uso de perfis falsos, fotos de terceiros, imagens ofensivas ou qualquer tipo de representação enganosa e ofensiva não é permitido.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                        Fotos inadequadas: é vetada a utilização de fotos íntimas ou ofensivas, que possam assediar ou constranger os demais usuários.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                        Uso Indevido de Check-ins: O Blinker deve ser utilizado em locais onde você realmente esteja presente,
                                        e o uso indevido para simular check-ins em outros lugares é proibido.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                        Sistema de Denúncias e Penalidades: Os usuários podem denunciar comportamentos inadequados diretamente pelo aplicativo.
                                        As denúncias podem ser validadas automaticamente e  a conta será suspensa por 24 horas, impossibilitando o usuário de se cadastrar e fazer novos check-ins.
                                        Denúncias recorrentes em dias distintos resultarão no banimento permanente da conta.
                                    </p>
                                </li>
                            </ul>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O Blinker reserva-se o direito de suspender ou encerrar contas que violem qualquer uma dessas regras, com ou sem aviso prévio.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                5. Direitos e Responsabilidades do Blinker
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O Blinker compromete-se a fornecer um serviço seguro e eficiente,
                                mas não se responsabiliza por qualquer evento ou comportamento ocorrido dentro de estabelecimentos físicos
                                (como casas noturnas, bares ou outros locais públicos) que possam ser listados como pontos de check-in.
                                O Blinker não possui vínculo com serviços oferecidos pelos estabelecimentos ou eventos realizados nesses locais,
                                salvo ocasiões devidamente sinalizadas e divulgadas pelo perfil do Blinker.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                6. Privacidade e Coleta de Dados
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                Ao usar o Blinker, você concorda com a coleta e o uso de informações conforme descrito em nossa Política de Privacidade.
                                Coletamos dados de localização e check-ins para aprimorar a experiência do usuário e proporcionar interações locais em tempo real.
                                Os dados dos usuários são usados exclusivamente pelo Blinker.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                Os dados podem ser usados em campanhas publicitárias e pesquisas do Blinker para aprimorar o marketing e a experiência no app.
                                Os usuários possuem o direito de solicitar a remoção de seus dados a qualquer momento pelo email contatoblinker@gmail.com.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                7. Segurança e Proteção do Usuário
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O Blinker se empenha em proteger os dados dos usuários com as melhores práticas de segurança e
                                incentiva que as interações ocorram de forma segura e respeitosa. No entanto, ao optar por interagir com outros usuários fora do aplicativo,
                                sugerimos que você tome as precauções necessárias para garantir sua segurança.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                8. Limitação de Responsabilidade
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O Blinker não se responsabiliza por:
                            </p>

                            <ul style={{
                                color: 'white',
                                fontFamily: 'Work Sans',
                                fontSize: '1rem',
                                letterSpacing: '0.015rem',
                                paddingLeft: '1rem',
                                marginLeft: '0.5rem',
                            }}>
                                <li>
                                    <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                        Quaisquer perdas ou danos indiretos, incidentais ou punitivos resultantes do uso ou incapacidade de uso do aplicativo.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                        Encontros, interações ou eventos que ocorram fora da plataforma.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                        Consequências emocionais ou de qualquer natureza resultantes das interações entre usuários.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                        Possíveis danos físicos a estabelecimentos, interrupções em eventos ou qualquer transtorno que usuários possam causar ao usar o aplicativo em locais físicos.
                                    </p>
                                </li>
                            </ul>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                9 - Check-in em espaços públicos ou privados
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O Blinker exibe locais públicos como referência de check-ins usando dados de geolocalização do Google Maps e de fontes públicas.
                                Os pontos de check-in não representam qualquer vínculo ou parceria com os locais exibidos.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                Caso o responsável por algum estabelecimento queira que o local não seja exibido como ponto de check-in,
                                ele poderá solicitar a remoção pelo e-mail contatoblinker@gmail.com.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O Blinker se compromete a tratar essas solicitações de forma responsável e atenciosa,
                                realizando o possível para remover o ponto solicitado e manter um bom relacionamento com os estabelecimentos.
                                Após a remoção, o Blinker reserva-se o direito de não adicionar novamente aquele local como ponto de check-in no futuro.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                10. Modificações nos Termos de Uso
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                O Blinker reserva-se o direito de modificar estes Termos de Uso a qualquer momento.
                                Alterações serão comunicadas aos usuários por meio de notificações no aplicativo ou e-mail.
                                O uso continuado do aplicativo após tais modificações implica a aceitação dos novos Termos.
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                11. Contato e Suporte
                            </p>

                            <p style={{ color: 'white', lineHeight: '1.5rem', fontFamily: 'Work Sans', fontSize: '1rem', letterSpacing: '0.015rem' }}>
                                Se você tiver dúvidas, preocupações ou quiser reportar algum comportamento inadequado de outros usuários,
                                entre em contato com nossa equipe de suporte pelo e-mail contatoblinker@gmail.com.
                                Estamos aqui para garantir que o Blinker seja uma plataforma segura e agradável para todos!
                            </p>

                        </div>
                    </div>
                </div>

            </PageTransition>

        </>
    );
}
export default UseTerms;