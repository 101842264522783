import React, { useState } from "react";
import '../../../../UI/Pages/App/SendInteraction.css'
import DefaultButton from "../../../Common/Buttons/DefaultButton";
import send_icon from '../../../../Assets/Icons/icon-send.svg';
import EmojiBox from "../../../Common/EmojiBox";
import useEmojis from "../../../State/Hooks/useEmojis";
import { sendMessage } from "../../../../lib/firebase/firestore";
import useCurrentUser from "../../../State/Hooks/useCurrentUser";
import { useNavigate, useParams } from "react-router-dom";
import icon_spacer from '../../../../Assets/Icons/icon-spacer-24-px.svg';
import icon_report from '../../../../Assets/Icons/icon-report.svg';
import icon_close from '../../../../Assets/Icons/icon-close-white.svg';
import AppBackground from "../AppBackground";
import InteractionHeader from "./InteractionHeader";
import DefaultModal from "../../../Common/DefaultModal";
import SentInteraction from "./SentInteraction";
import PageTransition from "../../../Common/PageTransition";
import Report from "./Report";
import useAnalytics from "../../../State/Hooks/useAnalytics";


const SendInteraction: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { emojis } = useEmojis();
    const { trackEvent } = useAnalytics();
    const { currentUser, usersList, currentRoom } = useCurrentUser();
    const [isSentInteraction, setIsSentInteraction] = useState<boolean>(false);
    const [showReport, setShowReport] = useState<boolean>(false);

    const candidate = usersList.find(u => u.id == id);

    // const [selectedEmoji, setSelectedEmoji] = useState<string>(emojis?.find(e => e.title == 'Piscadinha')?.id ?? '');
    const [selectedEmoji, setSelectedEmoji] = useState<string>('');

    const navigate = useNavigate();

    const sendInteraction = async (message: any) => {
        currentUser.id && await sendMessage(
            currentUser.id,
            currentUser.name!,
            currentUser.photo!,
            candidate?.id,
            message,
            currentUser.roomId!
        );
  
        trackEvent('interagiu', {
            sala: currentRoom?.title || '',
            interacao: message,
        });

        setIsSentInteraction(true);
    }


    window.scrollTo(0, 0);

    return (
        <AppBackground>
            <div style={{ width: '100%' }} >
                <PageTransition>
                    <div className="send-interaction-main-div">
                        <InteractionHeader
                            leftButton={
                                <button className='interaction-header-icon-button' onClick={() => navigate('/')}>
                                    <img src={icon_close} alt="Voltar" style={{ paddingLeft: '2rem' }} />
                                </button>}
                            rightButton={
                                <button className='interaction-header-icon-button' onClick={() => setShowReport(true)}>
                                    <img src={icon_report} alt="Reportar" style={{ height: 28, paddingRight: '1.5rem' }} />
                                </button>
                            }
                            title={candidate?.name}
                        />
                        <div style={{ display: "flex", flexDirection: 'column', gap: '2rem', marginBottom: '4.5rem', alignItems: 'center' }}>
                            <div style={{
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '4rem',
                                overflow: "hidden",
                                borderRadius: '0.75rem',
                                width: '13.45rem',
                                maxHeight: '18.75rem',
                            }}>
                                <img src={candidate?.photo} alt={candidate?.name} className="send-interaction-photo" />
                            </div>
                            <p className="send-interaction-text-p">Enviar figurinha</p>
                            <div className="send-interaction-grid">
                                {emojis?.filter(e => e.regular === true).map((e) =>
                                    <EmojiBox key={e.id} emoji={e} selected={selectedEmoji === e.id} onEmojiClick={setSelectedEmoji} />
                                )}
                            </div>
                        </div >
                        <div className="send-interaction-button-div">
                            <div style={{ paddingInline: '1rem' }}>
                                <DefaultButton type={"button"} text="Enviar" iconRight={send_icon} onClick={() => sendInteraction(selectedEmoji)} disabled={!Boolean(selectedEmoji)} />
                            </div>
                        </div>
                    </div>
                </PageTransition>
            </div >
            <DefaultModal show={isSentInteraction} onClose={() => setIsSentInteraction(false)}>
                <SentInteraction name={candidate?.name} />
            </DefaultModal>
            <DefaultModal show={showReport} onClose={() => setShowReport(prev => !prev)}>
                <Report
                    onCancel={() => setShowReport(prev => !prev)}
                    data={{ candidateId: candidate?.id, reporterId: currentUser?.id }}
                />
            </DefaultModal>
        </AppBackground>
    )
};

export default SendInteraction;