import { analytics, logEvent } from "../../../lib/firebase/client";

const useAnalytics = () => {
  const trackEvent = (eventName: string, eventParams = {}) => {
    if (!analytics) {
      console.warn("Analytics não está disponível.");
      return;
    }

    // Desative em desenvolvimento (opcional)
    if (process.env.NODE_ENV === 'development') {
      console.log("[DEV] Evento rastreado:", eventName, eventParams);
      return;
    }

    // Envie o evento para o Firebase Analytics
    logEvent(analytics, eventName, eventParams);
  };

  return { trackEvent };
};

export default useAnalytics;