import React, { useEffect, useState } from "react";
import '../../../../UI/Pages/App/ViewInteraction.css'
import DefaultButton from "../../../Common/Buttons/DefaultButton";
import send_icon from '../../../../Assets/Icons/icon-send.svg';
import icon_close from '../../../../Assets/Icons/icon-close-white.svg'
import icon_spacer from '../../../../Assets/Icons/icon-spacer-24-px.svg'
import icon_report from '../../../../Assets/Icons/icon-report.svg'
import EmojiBox from "../../../Common/EmojiBox";
import AppBackground from "../AppBackground";
import useEmojis from "../../../State/Hooks/useEmojis";
import { useNavigate, useParams } from 'react-router-dom';
import useCurrentUser from "../../../State/Hooks/useCurrentUser";
import InteractionHeader from "./InteractionHeader";
import DefaultModal from "../../../Common/DefaultModal";
import SentInteraction from "./SentInteraction";
import { sendMessage, updateMessageClosedStatus } from "../../../../lib/firebase/firestore";
import PageTransition from "../../../Common/PageTransition";
import Report from "./Report";
import useAnalytics from "../../../State/Hooks/useAnalytics";

const ViewInteraction: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { currentUser, messages, currentRoom } = useCurrentUser();
    const { emojis } = useEmojis();
    const { trackEvent } = useAnalytics();
    const [isSentInteraction, setIsSentInteraction] = useState<boolean>(false);
    const [showReport, setShowReport] = useState<boolean>(false);
    const notification = messages.find(n => n.id == id);
    const receivedEmoji = emojis?.find(e => e.id == notification?.message);

    const [selectedEmoji, setSelectedEmoji] = useState<string>('');

    const navigate = useNavigate();


    window.scrollTo(0, 0);

    const textData = <p><span style={{ color: '#FFF' }}>{notification?.userFromName}</span> {receivedEmoji?.text}</p>;

    const sendInteraction = async (message: any) => {
        currentUser.id && await sendMessage(
            currentUser.id,
            currentUser.name!,
            currentUser.photo!,
            notification?.userFrom,
            message,
            currentUser.roomId!
        );

        trackEvent('interagiu', {
            sala: currentRoom?.title || '',
            interacao: message,
        });
        
        setIsSentInteraction(true);
    }

    useEffect(() => {
        if (notification?.closed) {
            updateMessageClosedStatus(notification.id, false);
        }
    }, [notification])

    return (
        <AppBackground>
            <PageTransition>
                <div className="view-interaction-main-div">
                    <InteractionHeader
                        leftButton={
                            <button className='interaction-header-icon-button' onClick={() => navigate('/notifications')}>
                                <img src={icon_close} alt="Voltar" style={{ paddingLeft: '2rem' }} />
                            </button>}
                        rightButton={
                            <button className='interaction-header-icon-button' onClick={() => setShowReport(true)}>
                                <img src={icon_report} alt="Reportar" style={{ height: 28, paddingRight: '1.5rem' }} />
                            </button>
                        }
                        title={notification?.userFromName}
                    />
                    <div style={{ display: "flex", flexDirection: 'column', gap: '1.5rem', marginBottom: 20, marginTop: '-1rem' }}>
                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
                            <div className="interaction-photo-div">
                                <img src={notification?.userFromPhoto} alt={notification?.userFromName} className="interaction-photo" />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="emoji-div-absolute">
                                <img src={receivedEmoji?.src} className="photo-emoji" />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                            <p className="received-emoji-text">{textData}</p>
                        </div>

                        {/* <div className="view-interaction-bar" /> */}

                        <div className="retribution-div">
                            <p className="retribution-p">Enviar resposta</p>
                            <div className="view-interaction-grid">
                                {emojis?.map((e) =>
                                    <EmojiBox key={e.id} emoji={e} selected={selectedEmoji === e.id} onEmojiClick={setSelectedEmoji} />
                                )}
                            </div>
                        </div>
                        <div className="view-interaction-buttons-div">
                            <div style={{ paddingInline: '1rem' }}>
                                <DefaultButton type={"button"} text="Enviar" iconRight={send_icon} onClick={() => sendInteraction(selectedEmoji)} disabled={!Boolean(selectedEmoji)} />
                            </div>
                        </div>
                    </div>
                </div>
            </PageTransition>
            <DefaultModal show={isSentInteraction} onClose={() => setIsSentInteraction(false)}>
                <SentInteraction name={notification?.userFromName} />
            </DefaultModal>
            <DefaultModal show={showReport} onClose={() => setShowReport(prev => !prev)}>
                <Report
                    onCancel={() => setShowReport(prev => !prev)}
                    data={{ candidateId: notification?.userFrom, reporterId: currentUser?.id }}
                />
            </DefaultModal>
        </AppBackground >
    )
};

export default ViewInteraction;