import { initializeApp, getApps } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "./config";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from 'firebase/messaging';

export const firebaseApp =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const messaging = getMessaging();
export const { vapidKey } = firebaseConfig;
export const analytics = getAnalytics(firebaseApp);
export { logEvent };

export const auth = getAuth(firebaseApp);

